import { log } from '../logging';
import { SEVERITY_TEXT } from '../constants';
import { tracer } from '../tracers';
import { getEnvKey } from 'constants/envs';
import { getIdsFromSpan, replaceIdWithPlaceholder } from '../utils';

export function otNavigation(location) {
    if (!location?.pathname) {
        return;
    }
    const path = `/#${location.pathname}`;
    const environment = getEnvKey();

    const span = tracer.startSpan(`Navigation: ${replaceIdWithPlaceholder(path)}`, {
        attributes: {
            path,
            environment,
        },
    });

    log('Page Navigation', SEVERITY_TEXT.INFO, {
        event: 'navigation',
        path,
        environment,
        ...getIdsFromSpan(span),
    });

    span.end();
}
