import { isString } from 'lodash';

/**
 * Truncates a long string, keeping only the beginning and the end.
 * @param {string} str - The input string.
 * @param {number} maxLength - Maximum allowed length of the string.
 * @param {number} keepLength - Number of characters to keep at the start and end.
 * @returns {string} - Truncated string with metadata.
 */
export function truncateString(str, maxLength = 500, keepLength = 50) {
    if (!isString(str)) {
        return str;
    }

    const strLength = str.length;
    if (strLength <= maxLength) {
        return str;
    }

    return `LENGTH: ${strLength}; TRUNCATED_DATA: ${str.slice(0, keepLength)}...${str.slice(-keepLength)}`;
}
