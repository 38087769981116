import axios from 'axios';
import { axiosCloudConfig } from '../api/config';
import at from '../constants/ActionTypes/Main';
import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import { getPaasAuthEnrollRequestParams } from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-request-params-generators';
import {
    getPAASAuthenticationAbstract,
    getPaasAuthIntegrationUuid,
} from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import { buildAbstractListDetailedRequestBody } from '../containers/Ezlo/EzloVirtualDeviceManager/utils';
import { isObject } from 'lodash';

const actions = {
    hideModal: () => (dispatch) => dispatch({ type: at.HIDE_MODAL }),
    // TODO: naming from lower case
    ShowCreateRule: () => (dispatch) => dispatch({ type: at.IS_SHOW_MODAL_CREATE_RULE }),
    // TODO: naming from lower case
    ShowClearMeshBot: () => (dispatch) => dispatch({ type: at.IS_SHOW_MODAL_CLEAR_MESH_BOT }),
    // TODO: naming from lower case
    ShowDeviceFunction: () => (dispatch) => dispatch({ type: at.IS_SHOW_MODAL_DEVICE }),
    // TODO: rewrite using async/await
    showNavigation: () => ({ type: at.SHOW_NAVIGATION }),

    hideNavigation: () => ({ type: at.HIDE_NAVIGATION }),

    getAbstractCapabilities: () => async (dispatch) => {
        try {
            dispatch({ type: at.GET_ABSTRACT_CAPABILITIES_LIST });

            return await axios
                .post(
                    API_CLOUD_EZLO_REQUEST_URL(),
                    {
                        call: 'abstract_capabilities_list',
                        version: '1',
                        params: {},
                    },
                    axiosCloudConfig(),
                )
                .then((response) => {
                    const {
                        data: { data, status },
                    } = response;

                    if (status === 0) {
                        throw new Error(data.error_text);
                    }

                    if (status === 1) {
                        dispatch({
                            type: at.GET_ABSTRACT_CAPABILITIES_LIST_SUCCESS,
                            abstractCapabilities: data.capabilities,
                        });
                    }
                })
                .catch(onError);
        } catch (e) {
            onError(e);
        }

        function onError(error) {
            bugsnagNotifyWrapper(error, { type: at.GET_ABSTRACT_CAPABILITIES_LIST_ERROR });
            dispatch({ type: at.GET_ABSTRACT_CAPABILITIES_LIST_ERROR, error });
        }
    },
    /**
     * Retrieves a detailed list of abstracts.
     *
     * Dispatches the 'getAbstractsList' action with the current state's abstract list to
     * start the fetch process. This function is intended to be called with `(dispatch, getState)`.
     *
     * @function
     * @param {Function} dispatch - The Redux dispatch function.
     * @param {Function} getState - A function that returns the current Redux state.
     */
    getAbstractListDetailed: (dispatch, getState) => {
        return dispatch(actions.getAbstractsList(getState().main.abstractsList.abstracts));
    },

    /**
     * Fetches a new list of abstracts from the cloud, returning data if successful or dispatching an error on failure.
     *
     * @function
     * @param {Array} initialAbstractList - The list of abstracts from the current Redux state or other source.
     * @returns {Function} A thunk action that takes (dispatch) and returns a Promise.
     */
    getAbstractsList: (initialAbstractList) => async (dispatch) => {
        dispatch({ type: at.GET_ABSTRACTS_LIST, payload: initialAbstractList });

        try {
            const response = await axios.post(
                API_CLOUD_EZLO_REQUEST_URL(),
                buildAbstractListDetailedRequestBody(),
                axiosCloudConfig(),
            );

            const {
                data: { data, status },
            } = response;
            if (status === 0) {
                throw new Error(data.error_text || data.error_message || JSON.stringify(data));
            }

            dispatch({
                type: at.GET_ABSTRACTS_LIST_SUCCESS,
                payload: data?.abstracts,
            });

            return response.data;
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.GET_ABSTRACTS_LIST_ERROR });
            dispatch({ type: at.GET_ABSTRACTS_LIST_ERROR, payload: { error } });

            return error;
        }
    },

    // TODO: apply refactoring
    initialSetup: () => (dispatch) => {
        dispatch({ type: at.OEM_IS_LOADING });
        dispatch({ type: at.OEM_IS_INITIALIZED });
    },
    // TODO: apply refactoring
    enrollToPAASAuthService: () => async (dispatch, getState) => {
        const state = getState();

        if (state.main?.paas?.paasAuthServiceUuid) {
            return;
        }

        const enrollment = getPAASAuthenticationAbstract(state.main?.abstractsList?.abstracts);
        if (enrollment?.uuid) {
            dispatch({
                type: at.ENROLL_TO_PAAS_AUTH_SUCCESS,
                payload: { paasAuthUuid: enrollment.uuid },
            });

            return;
        }

        try {
            const integrations = state.integrations?.integrations;
            if (integrations === null || !isObject(integrations)) {
                return;
            }
            dispatch({ type: at.ENROLL_TO_PAAS_AUTH });
            const paasAuthIntegrationUuid = getPaasAuthIntegrationUuid(Object.values(integrations));
            const paasEnrolmentResponse = await axios.post(
                API_CLOUD_EZLO_REQUEST_URL(),
                getPaasAuthEnrollRequestParams(paasAuthIntegrationUuid),
                axiosCloudConfig(),
            );
            const {
                data: {
                    data: { uuid, error_text },
                },
            } = paasEnrolmentResponse;

            if (!uuid) {
                throw new Error(`Missing PAAS Auth uuid. ${error_text}`);
            }

            dispatch({
                type: at.ENROLL_TO_PAAS_AUTH_SUCCESS,
                payload: { paasAuthUuid: uuid },
            });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.ENROLL_TO_PAAS_AUTH_ERROR });
            dispatch({ type: at.ENROLL_TO_PAAS_AUTH_ERROR, error });

            return error;
        }
    },

    setStatePageBeingEdited: (currentPageState) => (dispatch) =>
        dispatch({ type: at.SET_STATE_PAGE_BEING_EDITED, payload: currentPageState }),

    confirmUserAction: (cb, fromPage) => (dispatch, getState) => {
        const { main } = getState();
        const { editableComponent } = main;

        if (editableComponent?.isDirty) {
            dispatch({ type: at.IS_SHOW_MODAL_CLEAR_MESH_BOT, payload: fromPage });
            dispatch({ type: at.SAVE_USER_ACTION, payload: cb });
        } else {
            cb();
        }
    },

    clearSavedAction: () => (dispatch) => dispatch({ type: at.CLEAR_SAVED_ACTION }),

    setConfirmationUser: (value) => (dispatch, getState) => {
        const { main } = getState();
        const { editableComponent } = main;

        if (Object.keys(editableComponent).length) {
            dispatch({ type: at.SET_CONFIRMATION_USER, payload: value });
        }
    },
};

export default actions;
