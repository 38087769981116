import { SYSTEM_HISTORY_URL } from '../constants/URLs';
import Axios from 'axios';
import { buildAxiosConfig } from './config';
import { GET_EVENTS } from 'lib/opentelemetry/constants/spansNames';

export const apiGetSystemHistoryList = (params) => {
    const url = SYSTEM_HISTORY_URL(params);

    return Axios.get(url, buildAxiosConfig(GET_EVENTS));
};
