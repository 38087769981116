import React from 'react';

export const IcActionsUsercode = (props) => {
    return (
        <svg {...props} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15.5C21 16.9 19.9 18 18.5 18C17.1 18 16 16.9 16 15.5C16 14.1 17.1 13 18.5 13C19.9 13 21 14.1 21 15.5ZM28 13C26.6 13 25.5 14.1 25.5 15.5C25.5 16.9 26.6 18 28 18C29.4 18 30.5 16.9 30.5 15.5C30.5 14.1 29.4 13 28 13ZM37.5 13C36.1 13 35 14.1 35 15.5C35 16.9 36.1 18 37.5 18C38.9 18 40 16.9 40 15.5C40 14.1 38.9 13 37.5 13ZM18.5 22C17.1 22 16 23.1 16 24.5C16 25.9 17.1 27 18.5 27C19.9 27 21 25.9 21 24.5C21 23.1 19.9 22 18.5 22ZM37.5 22C36.1 22 35 23.1 35 24.5C35 25.9 36.1 27 37.5 27C38.9 27 40 25.9 40 24.5C40 23.1 38.9 22 37.5 22ZM34.5 43L36.5 36.4C37.1 34.4 36.2 32.3 34.5 31.3L30.5 29V23.5C30.5 22.1 29.4 21 28 21C26.6 21 25.5 22.1 25.5 23.5V33L19.5 30C18.3 31.8 18.4 34.1 19.8 35.8L25.5 43H34.5Z" />
        </svg>
    );
};

export default IcActionsUsercode;
