import { log } from '../../logging';
import { SEVERITY_TEXT } from '../../constants';

/**
 * Custom logger for OpenTelemetry diagnostic messages.
 * This logger sends diagnostic logs to the OpenTelemetry logging system instead of the console.
 */
export class DiagOTLLogger {
    /**
     * Logs a warning message with additional arguments.
     * @param {string} message - The warning message to log.
     * @param {...any} args - Additional arguments to include in the log.
     */
    warn(message, ...args) {
        log('OpenTelemetry Diag', SEVERITY_TEXT.INFO, {
            message,
            args: JSON.stringify(args),
        });
    }
    /**
     * Logs a debug message with additional arguments.
     * @param {string} message - The debug message to log.
     * @param {...any} args - Additional arguments to include in the log.
     */
    debug(message, ...args) {
        log('OpenTelemetry Diag', SEVERITY_TEXT.DEBUG, {
            message,
            args: JSON.stringify(args),
        });
    }
    /**
     * Logs an error message with optional attributes.
     * @param {string} message - The error message to log.
     * @param {Object<string, any>} [attributes={}] - Additional attributes to include in the log.
     */
    error(message, attributes) {
        log('OpenTelemetry Diag', SEVERITY_TEXT.ERROR, {
            message,
            ...attributes,
        });
    }
}
