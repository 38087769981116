import { axiosCloudConfig } from '../../../../../../../../api/config';
import { API_CLOUD_EZLO_REQUEST_URL } from '../../../../../../../../constants/URLs';
import {
    buildIntegrationSetupRequestParams,
    buildIntegrationsGetConfigRequestBody,
} from '../../../../../../EzloParjectsSettings/utils';
import { INDEX_OF_UUID_INDEX } from '../paas-constants';
import { getEnumListMethodRequestBody, getEnumListMethodRequestBodyForTrigger } from './paas-request-params-generators';
import { deleteAbstractRequestParams } from './paas-responce-extractors';
import Axios from 'axios';

const apiGetIntegrationsConfig = async (parjectUuid) => {
    const params = {
        uuids: [parjectUuid],
    };
    const integrationsConfigParams = buildIntegrationsGetConfigRequestBody(params);

    const response = await Axios.post(API_CLOUD_EZLO_REQUEST_URL(), integrationsConfigParams, axiosCloudConfig());

    return response?.data?.data?.integrations?.[parjectUuid]?.config_entity?.index;
};

const findUuidIndex = (obj = {}, value) => {
    const foundUuidIndex = Object.entries(obj)?.find(([, val]) => val?.abstract?.uuid === value);

    return foundUuidIndex?.[INDEX_OF_UUID_INDEX];
};

const getUuidIndex = async (parjectUuid, abstractUuid) => {
    const result = await apiGetIntegrationsConfig(parjectUuid);

    return findUuidIndex(result, abstractUuid);
};

export const apiDeleteAbstractByUuid = async (abstractUuid, parjectUuid) => {
    const uuidIndex = await getUuidIndex(parjectUuid, abstractUuid);
    const deleteAbstractParams = deleteAbstractRequestParams(parjectUuid, uuidIndex);

    return await Axios.post(API_CLOUD_EZLO_REQUEST_URL(), deleteAbstractParams, axiosCloudConfig());
};

export const apiGetEnumList = async (accountUuid, enumListMethod) => {
    const enumListRequestParams = getEnumListMethodRequestBody(accountUuid, enumListMethod);

    const enumResponse = await Axios.post(API_CLOUD_EZLO_REQUEST_URL(), enumListRequestParams, axiosCloudConfig());

    const { data } = await enumResponse;

    return data?.data?.capabilities?.api?.variables?.result;
};

export const apiGetEnumListForTrigger = async (accountUuid, method) => {
    const enumListRequestParams = getEnumListMethodRequestBodyForTrigger(accountUuid, method);

    const enumResponseForTrigger = await Axios.post(
        API_CLOUD_EZLO_REQUEST_URL(),
        enumListRequestParams,
        axiosCloudConfig(),
    );
    const { data } = await enumResponseForTrigger;

    return data.data;
};

export const apiSubscribeToCallBackForTrigger = async (subscription) => {
    const subscriptionResponseForCallBackTrigger = await Axios.post(
        API_CLOUD_EZLO_REQUEST_URL(),
        subscription,
        axiosCloudConfig(),
    );

    return subscriptionResponseForCallBackTrigger?.data;
};

export const apiEnrollWithoutAuth = async (parjectUuid, token) => {
    const params = {
        uuid: parjectUuid,
        config_entity: {},
        info: {
            action: 'enroll',
            integration: parjectUuid,
            parameters: { token },
        },
    };

    const authUrlPromise = await Axios.post(
        API_CLOUD_EZLO_REQUEST_URL(),
        buildIntegrationSetupRequestParams(params),
        axiosCloudConfig(),
    );
    const { data } = await authUrlPromise;

    return data?.data?.info?.info?.data;
};
