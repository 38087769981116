import pako from 'pako';

export function compressData(data, options = { to: 'string' }) {
    try {
        const stringifiedData = JSON.stringify(data);

        return pako.deflate(stringifiedData, options);
    } catch (error) {
        return undefined;
    }
}

export function decompressData(compressedData, options = { to: 'string' }) {
    try {
        const decompressed = pako.inflate(compressedData, options);

        return JSON.parse(decompressed);
    } catch (error) {
        return undefined;
    }
}
