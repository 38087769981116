import { handleLargeJsonData } from './handleLargeJsonData';

/**
 * Prepares data for logging by compressing or trimming if it exceeds size limits.
 * @param {any} data - The input data.
 * @param {number|undefined} [dataSizeInBytes] - The data size in Bytes.
 * @returns {any} - Processed data optimized for size.
 */
export const prepareDataForLogging = (data, dataSizeInBytes) => {
    return data ? handleLargeJsonData(data, dataSizeInBytes) : data;
};
