import { LoggerProvider, BatchLogRecordProcessor } from '@opentelemetry/sdk-logs';
import { Resource } from '@opentelemetry/resources';
import { SEMRESATTRS_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import { diag } from '@opentelemetry/api';
import * as otelLogs from '@opentelemetry/api-logs';
import packageJson from '../../../package.json';
import oemManager from 'services/oem';
import { getEnvKey } from 'constants/envs';
import { MAX_EXPORT_BATCH_SIZE, SEVERITY_TEXT } from './constants';
import getOtLogTimestamps from './utils/getOtLogTimestamps';
import { LogExporter, DiagOTLLogger } from './services';

const SERVICE_NAME = packageJson.name;

class OTLLogs {
    static globalLogger = null;

    constructor() {
        diag.setLogger(new DiagOTLLogger());
    }

    static getLogger() {
        return OTLLogs.globalLogger;
    }

    static getSeverityNumberByText(severityText) {
        return otelLogs.SeverityNumber[severityText.toUpperCase()] || otelLogs.SeverityNumber.UNSPECIFIED;
    }

    initializeOpenTelemetryLogger() {
        const oem_id = oemManager.oem.getOemId();
        const environment = getEnvKey();

        const loggerProvider = new LoggerProvider({
            resource: new Resource({
                [SEMRESATTRS_SERVICE_NAME]: SERVICE_NAME,
                oem_id,
                environment,
                hostname: window?.location?.hostname || 'unknown',
            }),
        });

        loggerProvider.addLogRecordProcessor(
            new BatchLogRecordProcessor(new LogExporter(), {
                maxExportBatchSize: MAX_EXPORT_BATCH_SIZE,
                scheduledDelayMillis: 1000,
                exportTimeoutMillis: 60000,
            }),
        );

        otelLogs.logs.setGlobalLoggerProvider(loggerProvider);
        OTLLogs.globalLogger = otelLogs.logs.getLogger(SERVICE_NAME);
    }

    log(body, severityText = SEVERITY_TEXT.DEBUG, attributes) {
        try {
            const logger = OTLLogs.globalLogger;
            if (!logger) {
                return;
            }

            logger.emit({
                // If there is an active span in the context, spanId and traceId will be added automatically.
                body,
                severityNumber: OTLLogs.getSeverityNumberByText(severityText),
                severityText,
                attributes,
                ...getOtLogTimestamps(),
            });
        } catch (caughtError) {
            diag.error('Error emitting log', { caughtError });
        }
    }
}

const otlLogsInstance = new OTLLogs();

export const { initializeOpenTelemetryLogger, log } = otlLogsInstance;
