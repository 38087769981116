import axios from 'axios';
import { APP_CONFIG_URL } from './constants';
import { buildOTConfig } from 'lib/opentelemetry/utils';
import { GET_APPLICATION_CONFIG } from 'lib/opentelemetry/constants/spansNames';

/**
 * Fetch config.json file
 * @returns {Promise<Object>} api response
 * */
export const apiGetApplicationConfig = () => {
    return axios.get(APP_CONFIG_URL, buildOTConfig(GET_APPLICATION_CONFIG));
};
