/**
 * Calculates the size of data in bytes.
 * @param {any} data - The input data.
 * @returns {number|undefined} - The size of the data in bytes if we have data.
 */
export const calculateSizeInBytes = (data) => {
    if (data === null || data === undefined) {
        return;
    }

    return new TextEncoder().encode(JSON.stringify(data)).length;
};
