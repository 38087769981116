import { DELETE_CONTROLLER_URL } from '../constants/URLs';
import Axios from 'axios';
import { buildMMSAuthHeadersConfig } from './config';
import { DELETE_CONTROLLER_FROM_ACCOUNT } from 'lib/opentelemetry/constants/spansNames';

export const apiRemoveController = (serial) => {
    const url = DELETE_CONTROLLER_URL(serial);

    return Axios.delete(url, buildMMSAuthHeadersConfig(DELETE_CONTROLLER_FROM_ACCOUNT));
};
