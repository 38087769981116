import { OTLPLogExporter } from '@opentelemetry/exporter-logs-otlp-http';
import { calculateSizeInBytes } from '../../utils';
import { OPEN_TELEMETRY_LOGS_URL } from '../../constants/api';

/**
 * Custom OpenTelemetry log exporter that extends OTLPLogExporter.
 * This exporter adds additional error handling and includes log size in case of export failure.
 */

export class LogExporter extends OTLPLogExporter {
    /**
     * Default options for the OTLP log exporter.
     * @type {Object}
     * @property {string} url - The endpoint URL for sending logs.
     * @property {Object} headers - HTTP headers for the request.
     * @property {boolean} keepAlive - Whether to use keep-alive connections.
     */
    static collectorOptions = {
        url: OPEN_TELEMETRY_LOGS_URL,
        headers: {},
        keepAlive: true,
    };
    /**
     * Initializes the log exporter with predefined collector options.
     */
    constructor() {
        super(LogExporter.collectorOptions);
    }

    /**
     * Exports log records to the OpenTelemetry collector.
     * If the export fails, it appends the log size in bytes to the error object.
     *
     * @param {Array<Object>} logRecords - The array of log records to be exported.
     * @param {function} resultCallback - Callback function to handle the export result.
     * @returns {Promise<void>} - Resolves when the export process completes.
     */
    async export(logRecords, resultCallback) {
        await super.export(logRecords, (exportResult) => {
            try {
                if (exportResult?.code === 1 && exportResult?.error && logRecords) {
                    exportResult.error.logRecordsSizeInBytes = calculateSizeInBytes(logRecords);
                }
            } finally {
                resultCallback(exportResult);
            }
        });
    }
}
