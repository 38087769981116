import {
    BLOCK_WITH_FIELDS_ARRAY,
    COMPARISON_DATA,
    EMPTY_STRING,
    OPERATOR_GREATER_THAN,
    OPERATOR_LESS_THAN,
    OPERATOR_GREATER_THAN_OR_EQUAL,
    OPERATOR_LESS_THAN_OR_EQUAL,
    OPERATOR_EQUAL,
    OPERATOR_NOT_EQUAL,
    MIN_VALUE_ZERO,
    MAX_VALUE_ONE_HUNDRED,
    MIN_VALUE_ONE,
    MAX_VALUE_NINETY_NINE,
} from '../../../../../constants/MeshbotConstant';
import { listCapabilitiesForController, rangeCapabilitiesForController } from '../../../../../constants/rulesInSelect';
import { MESHBOT_TYPES } from 'containers/Ezlo/EzloMeshbots/constants';

export const getControllerNodeValueForTrigger = (currentLocalVariableBlock, name) => {
    return currentLocalVariableBlock?.blocks[BLOCK_WITH_FIELDS_ARRAY]?.fields?.find((field) => field.name === name)
        ?.value;
};

export const getControllerNodeValueObjectFromNotBlock = (block) => {
    return block?.fields?.find((field) => field?.value)?.value || {};
};

export const getControllerNodeValueFromNotBlockByName = (block, name) => {
    return block?.fields?.find((field) => field?.name === name)?.value;
};

/**
 * This function filters the capabilities by hardware support of the controller and by meshbot type
 * @param {boolean} isControllerWithBattery - determines if the controller has a battery
 * @param {string} meshbotType - the meshbot type
 * @return {array} - the filtered capabilities
 */
export const filterCapabilitiesByHardwareSupportOfController = (isControllerWithBattery, meshbotType) => {
    if (isControllerWithBattery === undefined || meshbotType === undefined) {
        return [];
    }

    if (isControllerWithBattery && meshbotType !== MESHBOT_TYPES.EZLOPI) {
        return listCapabilitiesForController;
    }

    if (isControllerWithBattery === false || meshbotType === MESHBOT_TYPES.EZLOPI) {
        return listCapabilitiesForController.filter(
            (capability) => capability.value === COMPARISON_DATA.METHOD.CLOUD_CONNECTION,
        );
    }

    return [];
};
/**
 * Function that provides controller capabilities for trigger of meshbot
 * @param {boolean} isControllerWithBattery - determines if the controller has a battery
 * @param {boolean} [isGlobalRestriction] - determines if it's GlobalRestriction section
 * @param {string} meshbotType - the meshbot type
 * @return {array} - the capabilities
 */
export const getHubCapabilitiesForMeshBotTrigger = (isControllerWithBattery, isGlobalRestriction, meshbotType) => {
    if (isGlobalRestriction) {
        return rangeCapabilitiesForController;
    }

    return filterCapabilitiesByHardwareSupportOfController(isControllerWithBattery, meshbotType);
};

/**
 * Checks if the battery level value is valid.
 * @param {object} currentLocalVariableBlock - The current local block.
 * @param {string} name - The field name.
 * @returns {boolean}
 */
export const isBatteryLevelValid = (currentLocalVariableBlock, name) => {
    return (
        currentLocalVariableBlock?.blocks[BLOCK_WITH_FIELDS_ARRAY]?.fields?.find((field) => field.name === name)
            ?.value !== EMPTY_STRING
    );
};

/**
 * Returns the valid range of values based on the comparison operator.
 * If the operator is not supported, an empty object is returned.
 * @param {string} operator - The comparison operator. Supported values: '>', '<', '>=', '<=', '==', '!='.
 * @returns {Object} - An object with the minimum and maximum values of the range or an empty object if the operator is not supported.
 * @property {number} [min] - The minimum value of the range (optional, only if the operator is supported).
 * @property {number} [max] - The maximum value of the range (optional, only if the operator is supported).
 */
export const getRangeByOperator = (operator) => {
    switch (operator) {
        case OPERATOR_GREATER_THAN:
            return { min: MIN_VALUE_ZERO, max: MAX_VALUE_NINETY_NINE };
        case OPERATOR_LESS_THAN:
            return { min: MIN_VALUE_ONE, max: MAX_VALUE_ONE_HUNDRED };
        case OPERATOR_GREATER_THAN_OR_EQUAL:
        case OPERATOR_LESS_THAN_OR_EQUAL:
        case OPERATOR_EQUAL:
        case OPERATOR_NOT_EQUAL:
            return { min: MIN_VALUE_ZERO, max: MAX_VALUE_ONE_HUNDRED };
        default:
            return {};
    }
};
