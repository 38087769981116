import Axios from 'axios';
import {
    GET_ADDRESS_ELEMENT_URL,
    GET_ALL_INSTALLATIONS_URL,
    GET_GET_OR_UPDATE_MAILING_BILLING_ADDRESSES_URL,
    UPDATE_INSTALLATION_URL,
} from '../constants/URLs';
import { buildSimpleConfig } from './config';
import { ADDRESS_ELEMENT_TYPES } from '../constants/AccountInfo';
import {
    GET_BILLING_MAILING_INFO,
    GET_CONFIG_LIST_OF_CITIES_IN_A_SPECIFIC_REGION_AND_COUNTRY,
    GET_CONFIG_LIST_OF_COUNTRIES,
    GET_CONFIG_LIST_OF_REGIONS_OF_COUNTRY,
    GET_INSTALLATIONS_INFO,
    SET_UPDATE_BILLING_MAILING_ACCOUNT_INFO,
    SET_UPDATE_INSTALLATION_INFO,
} from 'lib/opentelemetry/constants/spansNames';

export const apiGetInstallations = async (PK_Account) => {
    const installationsData = await Axios.get(
        GET_ALL_INSTALLATIONS_URL(PK_Account),
        buildSimpleConfig(GET_INSTALLATIONS_INFO),
    );

    if (installationsData.data.Installations) {
        return installationsData.data.Installations;
    }
};
export const apiGetMailingBillingAddresses = async (PK_Account) => {
    const mailingBillingData = await Axios.get(
        GET_GET_OR_UPDATE_MAILING_BILLING_ADDRESSES_URL(PK_Account),
        buildSimpleConfig(GET_BILLING_MAILING_INFO),
    );

    if (mailingBillingData.data) {
        return mailingBillingData.data;
    }
};
export const apiUpdateInstallation = async (PK_Account, installationData) => {
    const { PK_Installation, payload } = installationData;

    return await Axios.put(
        UPDATE_INSTALLATION_URL(PK_Account, PK_Installation),
        payload,
        buildSimpleConfig(SET_UPDATE_INSTALLATION_INFO),
    );
};
export const apiUpdateMailingBillingAddresses = async (PK_Account, addressesData) => {
    return await Axios.put(
        GET_GET_OR_UPDATE_MAILING_BILLING_ADDRESSES_URL(PK_Account),
        addressesData,
        buildSimpleConfig(SET_UPDATE_BILLING_MAILING_ACCOUNT_INFO),
    );
};

export const apiGetCountries = async () => {
    const requestResponse = await Axios.get(
        GET_ADDRESS_ELEMENT_URL(ADDRESS_ELEMENT_TYPES.COUNTRIES),
        buildSimpleConfig(GET_CONFIG_LIST_OF_COUNTRIES),
    );

    return await requestResponse?.data?.Country;
};

export const apiGetRegions = async (PK_Country) => {
    return await Axios.get(
        GET_ADDRESS_ELEMENT_URL(ADDRESS_ELEMENT_TYPES.REGIONS, PK_Country),
        buildSimpleConfig(GET_CONFIG_LIST_OF_REGIONS_OF_COUNTRY),
    );
};

export const apiGetCities = async (PK_Country, PK_Region) => {
    return await Axios.get(
        GET_ADDRESS_ELEMENT_URL(ADDRESS_ELEMENT_TYPES.CITIES, PK_Country, PK_Region),
        buildSimpleConfig(GET_CONFIG_LIST_OF_CITIES_IN_A_SPECIFIC_REGION_AND_COUNTRY),
    );
};
