import { isArray, isObject, isString, mapValues, size } from 'lodash';
import { truncateString } from './truncateString';

import { compressDataForLogging } from './compressDataForLogging';

/**
 * Trims a JSON object to a specified depth.
 * @param {any} data - Input data (object or array).
 * @param {number} maxDepth - Maximum nesting depth.
 * @param {number} currentDepth - Current recursion depth.
 * @returns {any} - Trimmed object or string if depth is exceeded.
 */
export function trimJsonByDepth(data, maxDepth, currentDepth = 0) {
    if (isString(data)) {
        return truncateString(data, 300, 100);
    }

    if (!isObject(data) || data === null) {
        return data;
    }

    if ((isArray(data) || isObject(data)) && size(data) > 500) {
        return {
            note: 'Data is too large and has been compressed. Use Gzip decompression.',
            data: compressDataForLogging(data),
        };
    }

    if (currentDepth >= maxDepth) {
        return '...';
    }

    return mapValues(data, (value) => trimJsonByDepth(value, maxDepth, currentDepth + 1));
}
