import { PRECISION_STATUS, PRECISION_PERFORMANCE_NOW_STATUS } from '../constants';

/**
 * Creates a function to determine the precision of `performance.now()`.
 * It collects multiple measurements and stores the precision status in localStorage.
 *
 * @returns {function(number): string | null} - A function that takes a `performance.now()` value
 * and returns the precision status from localStorage (if available) or null.
 */
export function createPerformancePrecisionChecker() {
    const measurements = [];

    /**
     * Identifies whether `performance.now()` has high precision.
     * It stores the result in localStorage after collecting 10 measurements.
     *
     * @param {number} perfNow - The current `performance.now()` value.
     * @returns {string | null} - The precision status from localStorage (`HIGH` or `NOT_HIGH`), or null if not yet determined.
     */
    return function identifyPrecisionPerformanceNowStatus(perfNow) {
        const precisionPerfNowStatus = localStorage.getItem(PRECISION_PERFORMANCE_NOW_STATUS);
        if (!precisionPerfNowStatus) {
            measurements.push(perfNow);

            if (measurements.length >= 10) {
                const isAllIntegers = measurements.every((value) => value === Math.floor(value));
                localStorage.setItem(
                    PRECISION_PERFORMANCE_NOW_STATUS,
                    isAllIntegers ? PRECISION_STATUS.NOT_HIGH : PRECISION_STATUS.HIGH,
                );
            }
        }

        return precisionPerfNowStatus;
    };
}

/**
 * A function that checks the precision of `performance.now()`.
 * It is initialized using `createPerformancePrecisionChecker()`.
 */
export const identifyPrecisionPerformanceNowStatus = createPerformancePrecisionChecker();
