export const GET_INSTALLATIONS_INFO = 'Get installations info';
export const GET_BILLING_MAILING_INFO = 'Get Billing/Mailing info';
export const SET_UPDATE_INSTALLATION_INFO = 'Set/Update installation info';
export const SET_UPDATE_BILLING_MAILING_ACCOUNT_INFO = 'Set/Update Billing/Mailing account info';
export const GET_CONFIG_LIST_OF_COUNTRIES = 'Get config/list of countries';
export const GET_CONFIG_LIST_OF_REGIONS_OF_COUNTRY = 'Get config/list of regions of country';
export const GET_CONFIG_LIST_OF_CITIES_IN_A_SPECIFIC_REGION_AND_COUNTRY =
    'Get config/list of cities in a specific region and country';
export const GET_USER_DETAILS = 'Get user details';
export const GET_LEGACY_CLOUD_EXCHANGE_TOKEN = 'Get legacy cloud exchange token';
export const CREATE_A_NEW_USER = 'Create a new user';
export const RESET_ACCOUNT_PASSWORD = 'Reset account password';
export const DELETE_CONTROLLER_FROM_ACCOUNT = 'Delete controller from account';
export const GET_MMS_ACCOUNT_DEVICES_HUBS = 'Get mms account devices(hubs)';
export const GET_MMS_DEVICE_HUB_INFO = 'Get mms device(hub) info';
export const GET_MMS_ACCOUNT_PRIVATE_EULA = 'Get mms account private eula';
export const SET_PRIVACY_USER_EULA = 'Set privacy user eula';
export const GET_MMS_ACCOUNT_PRIVACY_NOTICE = 'Get mms account privacy notice';
export const SET_PRIVACY_USER_NOTICE = 'Set privacy user notice';
export const GET_MMS_ACCOUNT_PRIVACY_CONSENT = 'Get mms account privacy consent';
export const SET_PRIVACY_USER_CONSENTGROUP = 'Set privacy user consentgroup';
export const SET_PRIVACY_USER_COUNTRY_AGE = 'Set privacy user country age';
export const GET_MMS_ACCOUNT_KVS = 'Get mms account kvs';
export const GET_USERS_OF_SUPPORT_SERVICE_API = 'Get users of support service api';
export const GET_TICKET_PRIORITIES_OF_SUPPORT_SERVICE_API = 'Get ticket priorities of support service api';
export const GET_HELP_TOPICS_OF_SUPPORT_SERVICE_API = 'Get help topics of support service api';
export const CREATE_A_NEW_SUPPORT_TICKET = 'Create a new support ticket';
export const GET_ACCOUNT_USERS = 'Get account users';
export const GET_EVENTS = 'Get events';
export const SET_UPDATE_INFO_OF_ACCOUNT_USER = 'Set/Update info of account user';
export const DELETE_USER_ACCOUNT = 'Delete user(account)';
export const SET_UPDATE_PERMISSIONS_OF_ACCOUNT_USER = 'Set/Update permissions of account user';
export const SET_UPDATE_PASSWORD_OF_ACCOUNT_USER = 'Set/Update password of account user';
export const RESEND_VALIDATE_EMAIL = 'Resend validate email';
export const GET_INTEGRATED_DEVICES_STATIC_INFO = 'Get integrated devices static info';
export const UPLOAD_FILE = 'Upload file';
export const DOWNLOAD_FILE = 'Download file';
export const USER_LOGIN = 'User Login';
export const GET_APPLICATION_CONFIG = 'Get Application Config';
