import { isDataWithinLogSizeLimit } from './isDataWithinLogSizeLimit';
import { trimJsonByDepth } from './trimJsonByDepth';
import { compressDataForLogging } from './compressDataForLogging';
import { diag } from '@opentelemetry/api';

/**
 * Processes and optimizes JSON data if it exceeds the logging size limit.
 * @param {any} data - The input JSON data.
 * @param {number|undefined} [dataSizeInBytes] - The data size in Bytes.
 * @returns {any} - The optimized JSON object or an error note if processing fails.
 */
export function handleLargeJsonData(data, dataSizeInBytes) {
    try {
        if (isDataWithinLogSizeLimit({ sizeInBytes: dataSizeInBytes })) {
            return data;
        }

        const compressedData = compressDataForLogging(data);
        if (isDataWithinLogSizeLimit({ data: compressedData })) {
            return {
                note: 'Data is too large and has been compressed. Use Gzip decompression.',
                data: compressedData,
            };
        }
        diag.error(
            'The data of a response for one log is still large even after compressing. It is impossible to transfer them all.',
            { dataSizeInBytes },
        );

        const trimmedData = trimJsonByDepth(data, 7);
        if (isDataWithinLogSizeLimit({ data: trimmedData })) {
            return {
                note: 'Data is too large and has been trimmed.',
                data: trimmedData,
            };
        }

        throw new Error('Data is too large and could not be sufficiently reduced.');
    } catch (error) {
        return {
            note: 'Failed to process large data for logs. Caught an error.',
            error: error.message || JSON.stringify(error),
        };
    }
}
