/**
 * Generates an OpenTelemetry configuration object containing trace data.
 * @param {Object} params - Configuration parameters.
 * @param {string} params.spanName - The name of the span.
 * @returns {Object} - OpenTelemetry configuration with trace data.
 */
const otConfig = ({ spanName }) => {
    return {
        traceData: {
            spanName,
        },
    };
};

/**
 * Wraps an existing HTTP configuration function with OpenTelemetry tracing.
 * @param {Function} mainConfigFn - A function that returns an HTTP config.
 * @returns {Function} - A function that takes a `spanName` and optional params,
 * and returns an HTTP config with tracing data.
 */
export const httpConfigWithOt = (mainConfigFn) => {
    return (spanName, params = []) => {
        return {
            ...mainConfigFn(...params),
            ...otConfig({ spanName }),
        };
    };
};
/**
 * Merges an existing configuration with OpenTelemetry tracing data.
 * @param {Object} config - The existing configuration object.
 * @param {string} spanName - The span name to attach.
 * @returns {Object} - The merged configuration with OpenTelemetry tracing.
 */
export const combineConfigWithOt = (config, spanName) => ({
    ...config,
    ...otConfig({ spanName }),
});

/**
 * Builds a standalone OpenTelemetry configuration.
 * @param {string} spanName - The name of the span.
 * @returns {Object} - OpenTelemetry configuration with trace data.
 */
export const buildOTConfig = (spanName) => {
    return otConfig({ spanName });
};
