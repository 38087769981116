import { calculateSizeInBytes } from './calculateSizeInBytes';
import { LOGS_RESPONSE_DATA_LIMIT_IN_BYTES } from '../constants';

/**
 * Checks if the data size is within the logging limit.
 * Throws an error if neither `data` nor `sizeInBytes` is provided.
 *
 * @param {Object} params - Parameters for size validation.
 * @param {any} [params.data] - The input data to calculate size from.
 * @param {number} [params.sizeInBytes] - The precomputed data size in bytes.
 * @throws {Error} If both `data` and `sizeInBytes` are missing.
 * @returns {boolean} - `true` if the data size is within the limit, otherwise `false`.
 */
export const isDataWithinLogSizeLimit = ({ data, sizeInBytes }) => {
    if (!data && !sizeInBytes) {
        throw new Error('There must be at least one parameter');
    }
    const size = sizeInBytes || calculateSizeInBytes(data);

    return size <= LOGS_RESPONSE_DATA_LIMIT_IN_BYTES;
};
