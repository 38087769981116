import { identifyPrecisionPerformanceNowStatus } from './createPerformancePrecisionChecker';
import { PRECISION_STATUS } from '../constants';

/**
 * Retrieves a high-resolution timestamp using `performance.now()`.
 * If precision is not high, adds a random value to prevent duplicate timestamps.
 *
 * @returns {number | undefined} - The current high-resolution timestamp or `undefined` if `performance.now()` is unavailable.
 */
export function getPerformanceNow() {
    const perfNow = performance?.now();
    if (!perfNow) {
        return;
    }

    const status = identifyPrecisionPerformanceNowStatus(perfNow);

    if (PRECISION_STATUS.HIGH === status) {
        return perfNow;
    }

    return Number(perfNow) + Math.random();
}
