import { API_CLOUD_EZLO_REQUEST_URL } from 'constants/URLs';
import { MISSING_SPAN_NAME_ERROR_MESSAGE } from '../constants';

/**
 * Builds an OpenTelemetry span name for HTTP requests.
 *
 * - If `traceData.spanName` is provided, it returns that value.
 * - If the request is a **POST to the cloud API (`API_CLOUD_EZLO_REQUEST_URL`)** and contains a `call` in the payload,
 *   it formats the span as `"Cloud request, call: <callName>"`.
 * - Otherwise, it logs an error and returns a fallback span name formatted as `"HTTP <METHOD> request. <URL>"`.
 *
 * @param {Object} traceData - The trace metadata.
 * @param {string} [traceData.spanName] - The predefined span name (if available).
 * @param {Object} requestData - The request details.
 * @param {string} requestData.method - The HTTP method (e.g., `"GET"`, `"POST"`).
 * @param {string} requestData.url - The request URL.
 * @param {Object} [requestData.data] - The request body (optional).
 * @returns {string} - The generated span name.
 */
export const buildOTSpanName = (traceData, { method, url, data }) => {
    if (traceData?.spanName) {
        return traceData.spanName;
    }

    if (method?.toLowerCase() === 'post' && url === API_CLOUD_EZLO_REQUEST_URL() && data?.hasOwnProperty('call')) {
        return `Cloud request, call: ${data.call}`;
    }

    // eslint-disable-next-line no-console
    console.error(MISSING_SPAN_NAME_ERROR_MESSAGE);

    return `HTTP ${method.toUpperCase()} request. ${url}`;
};
