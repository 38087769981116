import { getPerformanceNow } from './getPerformanceNow';
import { withSafelyExecute } from './withSafelyExecute';

/**
 * Generates OpenTelemetry-compatible timestamps using `Date.now()` and `performance.now()`.
 *
 * @throws {Error} If `performance.now()` or `Date.now()` is missing.
 *
 * @returns {Object} - A timestamp object with OpenTelemetry format.
 * @returns {Array<number>} return.timestamp - The high-resolution timestamp `[seconds, nanoseconds]`.
 * @returns {Array<number>} return.observedTimestamp - The same timestamp as `timestamp`, used for observed time.
 */
export function getOtLogTimestamps() {
    const nowMs = Date.now();
    const seconds = Math.floor(nowMs / 1000);
    const performanceNow = getPerformanceNow();
    if (!performanceNow || !seconds || !nowMs) {
        throw new Error('Missing performanceNow or seconds or nowMs');
    }

    const nanoseconds = (nowMs % 1000) * 1000000 + (Math.floor(performanceNow * 1000000) % 1000000);
    const hrTime = [seconds, nanoseconds];

    return {
        timestamp: hrTime,
        observedTimestamp: hrTime,
    };
}

/**
 * A wrapped version of `getOtLogTimestamps` that ensures safe execution.
 */
export default withSafelyExecute(getOtLogTimestamps, () => ({}));
