import { compressData } from 'lib/pako';

/**
 * Compresses data using Gzip and encodes it in Base64.
 * @param {any} data - The input data to compress.
 * @returns {string} - The compressed data in Base64 format or error info if an error occurs.
 */
export function compressDataForLogging(data) {
    try {
        const compressed_str = compressData(data, { to: 'string', level: 6 });

        return btoa(compressed_str);
    } catch (error) {
        return `Compression error: ${error}`;
    }
}
