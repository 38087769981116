export const MMS = 'mms';
export const CLOUD = 'cloud';

export const EVENTS = {
    RESPONSE_RECEIVED: 'response_received',
    RESPONSE_ERROR: 'response_error',
    REQUEST_STARTED: 'request_started',
    WS_SEND_RESPONSE_RECEIVED: 'ws.send.response_received',
    WS_SEND_REQUEST_STARTED: 'ws.send.request_started',
};
export const STAGES = {
    REQUEST: 'request',
    RESPONSE: 'response',
};

export const SEVERITY_TEXT = {
    ERROR: 'ERROR',
    INFO: 'INFO',
    DEBUG: 'DEBUG',
};

export const HUB_COMMAND = 'hub-command';
export const HUB_BROADCAST = 'hub-broadcast';
export const STATUSES = {
    OK: 'OK',
    ERROR: 'ERROR',
};

export const HTTP_CLIENTS = {
    FETCH: 'fetch',
    AXIOS: 'axios',
};

export const PRECISION_STATUS = {
    NOT_HIGH: 'not_high',
    HIGH: 'high',
};
export const PRECISION_PERFORMANCE_NOW_STATUS = 'precision_performance_now_status';
export const MAX_EXPORT_BATCH_SIZE = 6;
export const LOGS_METADATA_LIMIT_IN_BYTES = 524288;
export const LOGS_RESPONSE_DATA_LIMIT_IN_BYTES = Math.floor(LOGS_METADATA_LIMIT_IN_BYTES / MAX_EXPORT_BATCH_SIZE);
export const MISSING_SPAN_NAME_ERROR_MESSAGE = `❌ A request without spanName was detected. Please ensure that spanName is provided in the request config.

📌 **Solution: Use OpenTelemetry utility functions to build the request config.**  
   - **Recommended approach:** Use the functions from **lib/opentelemetry/utils/httpConfigWithOt.js**  

🔹 **Examples of correct usage:**
   1️⃣ \`Axios.post(url, data, buildOTConfig(USER_LOGIN));\`
   2️⃣ \`Axios.post(url, data, combineConfigWithOt(mainConfig, USER_LOGIN));\`
   3️⃣ **Define a reusable config with OpenTelemetry logic:**
      - **In** \`api/config.js\`:
        \`export const buildSimpleConfig = httpConfigWithOt(simpleConfig);\`
      - **Usage in requests:**
        - \`Axios.get(GET_USER_DETAILS_URL(PK_User), buildSimpleConfig(GET_USER_DETAILS));\`
        - \`Axios.post(GET_CREATE_USER_URL(), data, buildSimpleConfig(CREATE_A_NEW_USER, [options]));\`

📌 **Next Steps:**  
   - Define the span name as a constant in **lib/opentelemetry/constants/spansNames.js**  
   - Always use these constants when making requests.

⚠️ **If you see this error, please fix it to maintain trace consistency!**`;
